<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                      <h5 class='complain-title'>{{ $t('externalUserIrrigation.review') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12">
                        <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? note.review_note_bn : note.review_note }}</p>
                    </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { complainReviewNote } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      this.getComplainNote()
    }
  },
  data () {
    return {
        loader: true,
        note: ''
    }
  },
  computed: {
  },
  methods: {
    async getComplainNote () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${complainReviewNote}/${this.$props.id}`)
      if (result.success) {
        this.note = result.data
      }
      this.loader = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
