<template>
  <b-container fluid>
    <b-row v-if="loadMsg">
        <b-col>
            <b-overlay :show='true'>
                <h4 class="text-center">{{ $t('globalTrans.loading') }} ...</h4>
            </b-overlay>
        </b-col>
    </b-row>
    <div v-else>
        <b-row v-if="overlay" variant="default" show>
            <div class="box-message col-md-12 mt-5 text-danger">
                <div class="arrow">
                    <div class="outer"></div>
                    <div class="inner"></div>
                </div>
                <div class="message-body">
                    <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>
                      <!-- {{ $t('externalUserIrrigation.dashboard_message') }} -->
                      {{ $t('externalPanel.profile_complete_msg_part1')}}
                      <router-link :to="{ path: '/irrigation/my-profile' }">
                        {{ $t('externalPanel.profile_complete_msg_part2')}}
                      </router-link>
                      {{ $t('externalPanel.profile_complete_msg_part3')}}
                    </p>
                </div>
            </div>
        </b-row>
        <b-row v-else>
        <b-col md="12">
            <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('externalUserIrrigation.complain_application_list') }}</h4>
            </template>
            <template v-slot:headerAction>
                <b-button class="btn_add_new" v-b-modal.modal-1 @click="resetId">
                <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                </b-button>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                <b-row>
                    <b-col md="12" class="table-responsive">
                    <b-table thead-class="table_head" bordered hover :items="complains" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                        <template v-slot:cell(index)="data">
                        {{ $n(data.index + 1) }}
                        </template>
                        <template v-slot:cell(org_name)="data">
                        {{ data.item.org_name }}
                        </template>
                        <template v-slot:cell(org_name_bn)="data">
                        {{ data.item.org_name_bn }}
                        </template>
                        <template v-slot:cell(complain_id)="data">
                        {{ data.item.complain_id }}
                        </template>
                        <template v-slot:cell(district_en)="data">
                        {{ data.item.district_en }}
                        </template>
                        <template v-slot:cell(district_bn)="data">
                        {{ data.item.district_bn }}
                        </template>
                        <template v-slot:cell(subject)="data">
                        {{ data.item.subject }}
                        </template>
                        <template v-slot:cell(subject_bn)="data">
                        {{ data.item.subject_bn }}
                        </template>
                        <template v-slot:cell(details)="data">
                        <div class="text-center">
                            <b-button v-b-modal.modal-2 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View Application" @click="view(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                        </div>
                        </template>
                        <template v-slot:cell(attachment)="data">
                        <a v-if="data.item.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/farmer-complain/original/' + data.item.attachment" title="Download"><i class="ri-download-cloud-fill"></i></a>
                        </template>
                        <template v-slot:cell(status)="data">
                        {{ getStatus(data.item.status) }}
                        </template>
                        <template v-slot:cell(progress_type)="data">
                        <span class="badge badge-primary" v-if="data.item.progress_type === 1">{{$t('globalTrans.partial') }}</span>
                        <span class="badge badge-danger" v-if="data.item.progress_type === 2">{{$t('globalTrans.notDone') }}</span>
                        <span class="badge badge-success" v-if="data.item.progress_type === 3">{{$t('globalTrans.complete') }}</span>
                        </template>
                        <template v-slot:cell(note)="data">
                        <div class="text-center">
                            <b-button v-if="data.item.resolved_id !== null" v-b-modal.modal-3 variant=" iq-bg-success mr-1 mb-1" size="sm" title="Resolved Note" @click="view(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                            <b-button v-if="data.item.review_id !== null" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" title="Review Note" @click="view(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                        </div>
                        </template>
                    </b-table>
                    <b-pagination
                        align="center"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        />
                    </b-col>
                </b-row>
                </b-overlay>
            </template>
            </iq-card>
            <b-modal id="modal-1" size="lg" :title="$t('externalUserIrrigation.complain_application')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <FormV :id="testId"/>
            </p>
            </b-modal>
            <b-modal id="modal-2" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <ApplicationDetails :id="testId" />
            </p>
            </b-modal>
            <b-modal id="modal-3" size="lg" :title="$t('externalUserIrrigation.resolved')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <ResolvedNote :id="testId" />
            </p>
            </b-modal>
            <b-modal id="modal-4" size="lg" :title="$t('externalUserIrrigation.review')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <ReviewNote :id="testId" />
            </p>
            </b-modal>
        </b-col>
        </b-row>
    </div>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerComplainList, farmerInfo } from '../../api/routes'
import FormV from './Form'
import ApplicationDetails from './ApplicationDetails'
import ResolvedNote from './ResolvedNote'
import ReviewNote from './ReviewNote'

export default {
  name: 'UiDataTable',
  components: {
    FormV,
    ApplicationDetails,
    ResolvedNote,
    ReviewNote
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.commonObj.perPage,
        slOffset: 1
      },
      baseUrl: irriSchemeServiceBaseUrl,
      testId: 0,
      overlay: true,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      irriUrl: irriSchemeServiceBaseUrl,
      farmer_id: this.$store.state.Auth.accessUserId,
      complains: [],
      loadMsg: false
    }
  },
  computed: {
    formTitle () {
      return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.commonObj.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.commonObj.listReload
    },
    loadingState () {
      return this.$store.state.ExternalUserIrrigation.static.listReload
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('pump_install.organization'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.complain_id'), class: 'text-left' },
          { label: this.$t('pump_install.district'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.subject'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.details'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.attachment'), class: 'text-left' },
          { label: this.$t('globalTrans.progress_type'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.note'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'complain_id' },
          { key: 'district_bn' },
          { key: 'subject_bn' },
          { key: 'details' },
          { key: 'attachment' },
          { key: 'progress_type' },
          { key: 'status' },
          { key: 'note' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'complain_id' },
          { key: 'district_en' },
          { key: 'subject' },
          { key: 'details' },
          { key: 'attachment' },
          { key: 'progress_type' },
          { key: 'status' },
          { key: 'note' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.checkUser().then((res) => {
        if (res.loggedFarmerId) {
            this.loadData()
        }
    })
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    searchData () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    view (item) {
      this.testId = item.id
    },
    async checkUser () {
        this.loadMsg = true
      return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo).then(response => {
          this.loadMsg = false
          if (response.success) {
              this.overlay = false
              this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
              return { loggedFarmerId: response.data.id }
          }
      })
    },
    async loadData () {
        this.loadMsg = true
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: true })
      await RestApi.getData(irriSchemeServiceBaseUrl, farmerComplainList + '/' + this.farmer_id).then(response => {
        this.loadMsg = false
        if (response.success) {
          this.complains = this.formatList(response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
      })
    },
    formatList (data) {
      try {
        const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
        let tmpData = {}
        const listData = data.map(item => {
          tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
          const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
          tmpData = districtList.find(district => district.value === item.far_district_id)
          const districtData = { district_en: tmpData.text_en, district_bn: tmpData.text_bn }
          return Object.assign({}, item, orgData, districtData)
        })
        return listData
      } catch (error) {
        if (error) {
         //
        }
        return []
      }
    },
    getStatus (status) {
      if (status === 1) {
        return 'Pending'
      } else if (status === 2) {
        return 'Closed'
      } else if (status === 3) {
        return 'Review'
      } else if (status === 4) {
        return 'Required Maintenance'
      } else if (status === 5) {
        return 'Maintenance Task'
      } else if (status === 6) {
        return 'Requisition'
      } else if (status === 7) {
        return 'Complete'
      } else {
        return 'Resunk'
      }
    }
  }
}
</script>
